<template>
  <div class="page-table only-y mainDiv">
    <div class="card-base baslikBg animated fadeInUp">
      {{ $t("src.views.apps.yonetici.liste.title") }}
      <div class="tanimlamaButon">
        <el-tooltip
          :content="$t('src.views.apps.genel.refresh')"
          :open-delay="500"
          placement="top"
        >
          <el-button
            v-on:click="refreshPage()"
            size="mini"
            type="text"
            icon="el-icon-refresh"
            class="mr-5"
          ></el-button>
        </el-tooltip>
        <el-tooltip
          :content="$t('src.views.apps.genel.yeniKaydet')"
          :open-delay="500"
          placement="bottom"
        >
          <el-button
            v-on:click="routeYonetici('Personel Tanımla')"
            size="mini"
            type="text"
            icon="el-icon-plus"
          ></el-button>
        </el-tooltip>
      </div>
    </div>
    <div
      class="page-header card-base card-shadow--medium animated fadeInUp"
      v-loading="loading || stateLoading || searchLoading"
      :element-loading-text="
        loading
          ? $t('src.views.apps.yonetici.liste.listLoading')
          : stateLoading
          ? $t('src.views.apps.yonetici.liste.stateLoading')
          : 'Personel Aranıyor. Lütfen Bekleyiniz.'
      "
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 1)"
    >
      <el-row class="mt-0 mb-10" v-if="yetkiTuru == 1">
        <el-col :lg="9" :md="12" :sm="24" :xs="24">
          <div>
            <el-radio-group
              size="small"
              v-on:change="handleChange($event)"
              v-model="radio"
              fill="#E5F5F9"
              text-color="gray"
              :disabled="searchLoading"
            >
              <el-radio-button label="1">
                <i
                  class="mdi mdi-account mdi-18px"
                  style="color: #28323b; margin-right: 5px"
                ></i>
                <transition name="slide-fade">
                  <label v-if="sozlesmeKategoriID === '1'">{{
                    $t("src.views.apps.yonetici.liste.table.sozlesmeKategori1")
                  }}</label>
                </transition>
              </el-radio-button>
              <el-radio-button label="2">
                <i
                  class="mdi mdi-account mdi-18px"
                  style="color: #006cae; margin-right: 5px"
                ></i>
                <transition name="slide-fade">
                  <label v-if="sozlesmeKategoriID === '2'">{{
                    $t("src.views.apps.yonetici.liste.table.sozlesmeKategori2")
                  }}</label>
                </transition>
              </el-radio-button>
              <el-radio-button label="3">
                <i
                  class="mdi mdi-account mdi-18px"
                  style="color: #3595cf; margin-right: 5px"
                ></i>
                <transition name="slide-fade">
                  <label v-if="sozlesmeKategoriID === '3'">{{
                    $t("src.views.apps.yonetici.liste.table.sozlesmeKategori3")
                  }}</label>
                </transition>
              </el-radio-button>
              <el-radio-button label="4">
                <i
                  class="mdi mdi-account mdi-18px"
                  style="color: #ff8b00; margin-right: 5px"
                ></i>
                <transition name="slide-fade">
                  <label v-if="sozlesmeKategoriID === '4'">{{
                    $t("src.views.apps.yonetici.liste.table.sozlesmeKategori4")
                  }}</label>
                </transition>
              </el-radio-button>
              <el-radio-button label="5">
                <i
                  class="mdi mdi-account mdi-18px"
                  style="color: #181f38; margin-right: 5px"
                ></i>
                <transition name="slide-fade">
                  <label v-if="sozlesmeKategoriID === '5'">{{
                    $t("src.views.apps.yonetici.liste.table.sozlesmeKategori5")
                  }}</label>
                </transition>
              </el-radio-button>
              <el-radio-button label="6">
                <i
                  class="mdi mdi-account mdi-18px"
                  style="color: #ce1e1e; margin-right: 5px"
                ></i>
                <transition name="slide-fade">
                  <label v-if="sozlesmeKategoriID === '6'">{{
                    $t("src.views.apps.yonetici.liste.table.sozlesmeKategori6")
                  }}</label>
                </transition>
              </el-radio-button>
              <el-radio-button label="7">
                <i
                  class="mdi mdi-account mdi-18px"
                  style="color: #0a8f3e; margin-right: 5px"
                ></i>
                <transition name="slide-fade">
                  <label v-if="sozlesmeKategoriID === '7'">{{
                    $t("src.views.apps.yonetici.liste.table.sozlesmeKategori7")
                  }}</label>
                </transition>
              </el-radio-button>

              <el-radio-button label="0">
                <i
                  class="mdi mdi-account mdi-18px"
                  style="color: #7469b6; margin-right: 5px"
                ></i>

                <transition name="slide-fade">
                  <label v-if="sozlesmeKategoriID === '0'">{{
                    $t("src.views.apps.yonetici.liste.table.personelList")
                  }}</label>
                </transition>
              </el-radio-button>
              <el-radio-button label="-1">
                <i
                  class="mdi mdi-account mdi-18px"
                  style="color: #6c0345; margin-right: 5px"
                ></i>
                <transition name="slide-fade">
                  <label v-if="sozlesmeKategoriID === '-1'">{{
                    $t("src.views.apps.yonetici.liste.table.managerList")
                  }}</label>
                </transition>
              </el-radio-button>
            </el-radio-group>
          </div>
        </el-col>
        <el-col :lg="5" :md="12" :sm="24" :xs="24">
          <el-col :lg="24" :md="24" :sm="24" :xs="24">
            <el-switch
              :class="'islemler2'"
              v-on:change="handleChange($event)"
              class="personellist-info-switch"
              style="display: block"
              v-model="selectIcon"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-value="1"
              inactive-value="0"
              active-text="Aktif Personeller"
              inactive-text="Pasif Personeller"
            >
            </el-switch>
          </el-col>
        </el-col>
        <el-col
          :lg="10"
          :md="24"
          :sm="24"
          :xs="24"
          v-if="yetkiTuru == '1' || yetkiTuru == '2'"
        >
          <ul class="islemler personellist-info-icons" v-if="isDesktop">
            <li class="detay"><i class="mdi mdi-eye"></i> Personel Detay</li>
            <li class="yesil2"><i class="mdi mdi-eye"></i> Şifre Gönder</li>
            <li class="duzenle">
              <i class="mdi mdi-circle-edit-outline"></i> Personeli Düzenle
            </li>
            <li v-if="selectIcon === '1'" class="sil">
              <i class="mdi mdi-trash-can"></i> Personeli Pasif Yap
            </li>
            <li v-if="selectIcon === '0'" class="aktif">
              <i class="mdi mdi-play"></i> Personeli Aktif Yap
            </li>
          </ul>
          <ul class="islemler2" v-else>
            <li
              class="duzenle"
              v-if="
                yetkiTuru == '1' ||
                (yetkiTuru == '2' && yetkiListesi.includes('YK-musteriU100'))
              "
            >
              <i class="mdi mdi-circle-edit-outline"></i>
              {{ $t("src.views.apps.musteri.liste.table.editCustomer") }}
            </li>
            <li v-if="selectIcon === '1'" class="sil">
              <i class="mdi mdi-trash-can"></i>
              {{ $t("src.views.apps.musteri.liste.table.setPassive") }}
            </li>
            <li v-else class="aktif">
              <i class="mdi mdi-play"></i>
              {{ $t("src.views.apps.musteri.liste.table.setActive") }}
            </li>
          </ul>
        </el-col>
      </el-row>
      <el-row class="mb-10" v-if="yetkiTuru == 2">
        <el-col :span="23">
          <div>
            <el-radio-group
              size="small"
              v-on:change="handleChange2($event)"
              v-model="radio"
              fill="#E5F5F9"
              text-color="gray"
            >
              <el-radio-button label="1">
                <i
                  class="mdi mdi-account mdi-24px"
                  style="color: #13ce66; margin-right: 5px"
                ></i>
                <transition name="slide-fade"
                  ><label v-if="selectIcon === '1'">{{
                    $t("src.views.apps.yonetici.liste.aktifYonetici")
                  }}</label>
                </transition>
              </el-radio-button>
              <el-radio-button label="0">
                <i
                  class="mdi mdi-account mdi-24px"
                  style="color: #ec205f; margin-right: 5px"
                ></i>
                <transition name="slide-fade"
                  ><label v-if="selectIcon === '0'">{{
                    $t("src.views.apps.yonetici.liste.pasifYonetici")
                  }}</label>
                </transition>
              </el-radio-button>
            </el-radio-group>
          </div>
        </el-col>
      </el-row>
      <el-table :data="yoneticiListesi" style="width: 100%" border stripe>
        <el-table-column>
          <el-table-column
            :label="$t('src.views.apps.genel.sira')"
            type="index"
            :index="indexMethod"
            width="100"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <el-table-column
            prop="ID"
            :label="$t('src.views.apps.yonetici.liste.table.yoneticiID')"
            width="100"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input
              clearable
              v-on:clear="personelAra"
              :placeholder="$t('src.views.apps.personel.search.personelAdiAra')"
              v-model="personelAdiAra"
              v-debounce:500ms="personelAra"
              size="mini"
              :key="props.$index"
            ></el-input>
          </template>
          <el-table-column
            prop="adSoyad"
            :label="$t('src.views.apps.yonetici.liste.table.adSoyad')"
            width="200"
          >
            <template slot-scope="prop">
              <div class="new-line-text">
                {{ prop.row.adSoyad }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input
              clearable
              v-on:clear="personelAra"
              :placeholder="
                $t('src.views.apps.personel.search.personelKullaniciAdiAra')
              "
              v-model="personelKullaniciAdiAra"
              v-debounce:500ms="personelAra"
              size="mini"
              :key="props.$index"
            ></el-input>
          </template>
          <el-table-column
            prop="yoneticiAdi"
            :label="$t('src.views.apps.yonetici.liste.table.userName')"
            :width="isDesktop ? 'auto' : '250'"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input
              clearable
              v-on:clear="personelAra"
              :placeholder="$t('src.views.apps.personel.search.yetkiTuruAra')"
              v-model="yetkiTuruAra"
              v-debounce:500ms="personelAra"
              size="mini"
              :key="props.$index"
            ></el-input>
          </template>
          <el-table-column
            :label="$t('src.views.apps.yonetici.liste.table.yetkiTuru')"
            :width="isDesktop ? 'auto' : '150'"
          >
            prop="yetkiTuru">
            <template slot-scope="prop">
              <p v-if="prop.row.rol === '1'">
                {{ $t("src.views.apps.yonetici.liste.table.yetkiRol1") }}
              </p>
              <p v-if="prop.row.rol === '2'">
                {{ $t("src.views.apps.yonetici.liste.table.yetkiRol2") }}
              </p>
              <p v-if="prop.row.rol === '3'">
                {{ $t("src.views.apps.yonetici.liste.table.yetkiRol3") }}
              </p>
            </template>
          </el-table-column>
        </el-table-column>
        <!--
        <el-table-column>
            <template slot="header" slot-scope="props">
                <el-input clearable v-on:clear="personelAra" :placeholder='$t("src.views.apps.personel.search.birimAra")' v-model="birimAra" v-debounce:500ms="personelAra" size="mini"></el-input>
            </template>
            <el-table-column
                :label='$t("src.views.apps.yonetici.tanimlama.BG")'
                width="auto"
                prop="sozlesmeKategoriID">
                <template slot-scope="prop">
                    <p v-if="prop.row.sozlesmeKategoriID === '1'">{{$t("src.views.apps.yonetici.liste.table.sozlesmeKategori1")}}</p>
                    <p v-if="prop.row.sozlesmeKategoriID === '2'">{{$t("src.views.apps.yonetici.liste.table.sozlesmeKategori2")}}</p>
                    <p v-if="prop.row.sozlesmeKategoriID === '3'">{{$t("src.views.apps.yonetici.liste.table.sozlesmeKategori3")}}</p>
                    <p v-if="prop.row.sozlesmeKategoriID === '4'">{{$t("src.views.apps.yonetici.liste.table.sozlesmeKategori4")}}</p>
                    <p v-if="prop.row.sozlesmeKategoriID === '5'">{{$t("src.views.apps.yonetici.liste.table.sozlesmeKategori5")}}</p>
                    <p v-if="prop.row.sozlesmeKategoriID === '6'">{{$t("src.views.apps.yonetici.liste.table.sozlesmeKategori6")}}</p>
                    <p v-if="prop.row.sozlesmeKategoriID === '7'">{{$t("src.views.apps.yonetici.liste.table.sozlesmeKategori7")}}</p>
                </template>
            </el-table-column>
        </el-table-column>-->
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input
              clearable
              v-on:clear="personelAra"
              :placeholder="$t('src.views.apps.personel.search.telefonAra')"
              v-model="telefonAra"
              v-debounce:500ms="personelAra"
              v-mask="'+90(###)### ## ##'"
              size="mini"
              :key="props.$index"
            ></el-input>
          </template>
          <el-table-column
            prop="cepTel"
            :label="$t('src.views.apps.yonetici.liste.table.telefon')"
            width="auto"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input
              clearable
              v-on:clear="personelAra"
              :placeholder="$t('src.views.apps.personel.search.dahiliAra')"
              v-model="dahiliAra"
              v-debounce:500ms="personelAra"
              v-mask="'######'"
              size="mini"
              :key="props.$index"
            ></el-input>
          </template>
          <el-table-column
            prop="dahiliTel"
            :label="$t('src.views.apps.yonetici.liste.table.dahili')"
            width="100"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input
              clearable
              v-on:clear="personelAra"
              :placeholder="$t('src.views.apps.personel.search.sabitAra')"
              v-model="sabitAra"
              v-debounce:500ms="personelAra"
              v-mask="'+90(###)### ## ##'"
              size="mini"
              :key="props.$index"
            ></el-input>
          </template>
          <el-table-column
            prop="sabitTel"
            :label="$t('src.views.apps.yonetici.liste.table.sabit')"
            width="150"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <el-table-column
            :label="$t('src.views.apps.genel.islem')"
            align="right"
            width="180"
          >
            <template slot-scope="scope">
              <el-tooltip
                content="Personel Detay"
                :open-delay="500"
                placement="bottom"
              >
                <el-button
                  v-if="root || yetkili || yetkiList.yoneticiDetayla"
                  class="personelButon"
                  v-on:click="yoneticiDetay(scope.row)"
                  type="text"
                  size="mini"
                  icon="mdi mdi-eye mdi-24px"
                >
                </el-button>
              </el-tooltip>
              <el-tooltip
                content="Şifre Sms Gönder"
                :open-delay="500"
                placement="bottom"
              >
                <el-button
                  v-if="root || yetkili || yetkiList.sifreGonder"
                  class="personelButon"
                  v-on:click="sifreSmsGonder(scope.row)"
                  type="text"
                  size="mini"
                  style="color: rgb(121, 206, 76)"
                  icon="mdi mdi-cellphone-message mdi-24px"
                >
                </el-button>
              </el-tooltip>
              <el-tooltip
                content="Personeli Düzenle"
                :open-delay="500"
                placement="bottom"
              >
                <el-button
                  v-if="root || yetkili || yetkiList.yoneticiGuncelleme"
                  class="personelButon"
                  v-on:click="routeYonetici('Personel Güncelle', scope.row)"
                  type="text"
                  style="color: #f7ba2a"
                  icon="mdi mdi-circle-edit-outline mdi-24px"
                >
                </el-button>
              </el-tooltip>
              <el-tooltip
                content="Personeli Pasif Yap"
                :open-delay="500"
                placement="bottom"
              >
                <el-button
                  v-if="
                    selectIcon === '1' &&
                    (root || yetkili || yetkiList.yoneticiSil)
                  "
                  class="buttonDel"
                  @click="
                    deleteDialogVisible = true;
                    selection = scope.row;
                    selectionIndex = scope.$index;
                  "
                  type="text"
                  icon="mdi mdi-trash-can mdi-24px"
                >
                </el-button>
              </el-tooltip>
              <el-tooltip
                content="Personeli Aktif Yap"
                :open-delay="500"
                placement="bottom"
              >
                <el-button
                  v-if="
                    selectIcon === '0' &&
                    (root || yetkili || yetkiList.yoneticiSil)
                  "
                  @click="
                    yoneticiDurumChange(scope.row, scope.$index, 1),
                      (deleteDialogVisible = false)
                  "
                  type="text"
                  icon="mdi mdi-play mdi-24px"
                >
                </el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
      <div
        v-if="yoneticiListesi.length == []"
        style="
          width: 100%;
          height: 50px !important;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        {{ $t("src.views.apps.messages.noData") }}
      </div>
      <el-pagination
        background
        layout="sizes,prev, pager, next, jumper, total"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="pageChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="page"
        :next-click.sync="page"
        :prev-click.sync="page"
        @next-click="pageChange"
        @prev-click="pageChange"
      >
      </el-pagination>
    </div>
    <user-dialog
      v-if="dialogVisibleYonetici"
      :dialogvisible.sync="dialogVisibleYonetici"
      :userdata="userDataBGSurec"
    ></user-dialog>
    <!---->
  </div>
</template>

<script>
import yoneticiService from "../../../WSProvider/YoneticiService";
import UserDialog from "@/components/UserDialog";
import notification from "../../../notification";
import functions from "../../../functions";
import jwt_decode from "jwt-decode";
import EventBus from "@/components/event-bus";

let userDataBGSurec = "";
export default {
  name: "PersonelListesi",
  components: {
    UserDialog,
  },
  mounted() {
    window.addEventListener("resize", this.checkDevice);
    const self = this;
    if (localStorage.getItem("userDataBGSurec")) {
      userDataBGSurec = jwt_decode(localStorage.getItem("userDataBGSurec"));
      if (userDataBGSurec["yetkiTuru"]) {
        if (userDataBGSurec["yetkiTuru"] === "1") {
          self.root = true;
          self.yetkiList.yoneticiSil = true;
          self.yetkiList.yoneticiDetayla = true;
          self.yetkiList.yoneticiGuncelleme = true;
          self.yetkiList.sifreGonder = true;
        }
        if (userDataBGSurec["yetkiTuru"] === "2") {
          self.yetkiList.yoneticiSil = true;
          self.yetkiList.yoneticiDetayla = true;
          self.yetkiList.yoneticiGuncelleme = true;
          self.yetkiList.sifreGonder = true;
        }
      }
    }
    //this.getYoneticiCount();
    this.getFirmaYoneticiList();
    EventBus.$on("yoneticiList", function (bool) {
      if (bool) {
        self.getFirmaYoneticiList();
        // self.getYoneticiCount();
      }
    });
  },
  watch: {
    page(val) {
      this.postPage = (val - 1) * this.sizes;
    },
    sehir(newValue) {
      if (newValue === "") this.musteriForm.ilce = "";
    },
  },
  data() {
    return {
      stateLoading: false,
      searchLoading: false,
      loading: false,
      isDesktop: false,
      page: 0,
      sizes: 10,
      postPage: 0,
      total: null,
      selectIcon: "1",
      radio: "1",
      root: false,
      yetkili: false,
      yetkiList: {
        yoneticiDetayla: false,
        yoneticiGuncelleme: false,
        yoneticiSil: false,
        sifreGonder: false,
      },
      dialogVisibleYonetici: false,
      userDataBGSurec: {},
      deleteDialogVisible: false,
      selection: "",
      selectionIndex: "",
      yoneticiListesi: [],
      personelAdiAra: "",
      personelKullaniciAdiAra: "",
      yetkiTuruAra: "",
      birimAra: "",
      telefonAra: "",
      dahiliAra: "",
      sabitAra: "",
      sozlesmeKategoriID: "1",
      birim: "",
    };
  },
  created() {
    this.checkDevice();
    if (localStorage.getItem("userDataBGSurec")) {
      this.yetkiTuru = this.$store.getters.getYetkiTuru;
      this.yetkiListesi = this.$store.getters.getYetkiListesi;
      this.birim = this.$store.getters.getSozlesmeKategoriID;
      //console.log("birim", this.birim)
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkDevice);
  },
  methods: {
    refreshPage() {
      this.stateLoading = false;
      this.loading = false;
      this.page = 1;
      this.postPage = 0;
      this.getFirmaYoneticiList();
      //  this.getYoneticiCount();
    },
    checkDevice() {
      this.isDesktop = window.innerWidth >= 768;
    },
    yoneticiDetay(scope) {
      this.userDataBGSurec = scope;
      this.dialogVisibleYonetici = true;
    },
    sifreSmsGonder(row) {
      this.$confirm(
        "Şifreyi sms göndermek istediğinize emin misiniz?",
        this.$t("src.views.apps.genel.uyari"),
        {
          confirmButtonText: this.$t("src.views.apps.genel.yes"),
          cancelButtonText: this.$t("src.views.apps.genel.no"),
          type: "warning",
        }
      )
        .then(() => {
          this.loading = true;
          yoneticiService
            .sifreSmsGonder(row.ID)
            .then((response) => {
              if (response.status == 200) {
                localStorage.setItem("userDataBGSurec", response.token);
                this.resetForm(formName);
                functions.sayfaKapat("personelList", this);
              }
              this.loading = false;
              notification.Status("success", this, response.msg);
            })
            .catch((err) => {
              if (err.responseJSON) {
                let error = err.responseJSON;
                if (error.status == 401) {
                  notification.Status(150, this);
                } else notification.Status("errorMsg", this, error.errMsg);
              } else {
                //notification.Status(503, this)
              }
              this.loading = false;
            });
        })
        .catch((e) => {});
    },
    handleChange(event) {
      this.postPage = 0;
      this.page = 1;
      console.log("event", event);
      console.log("this.radio", this.radio);
      this.sozlesmeKategoriID = this.radio;
      if (this.radio === "-1") {
        this.getYoneticiList();
      } else {
        this.getFirmaYoneticiList();
      }
      // this.getYoneticiCount();
      document
        .querySelector(".scrollable")
        .scrollTo({ top: 0, behavior: "smooth" });
    },

    handleChange2(event) {
      this.postPage = 0;
      this.page = 1;
      this.selectIcon = event;
      this.durum = this.radio;
      this.getFirmaYoneticiList();
      // this.getYoneticiCount();
      document
        .querySelector(".scrollable")
        .scrollTo({ top: 0, behavior: "smooth" });
    },

    getYoneticiList() {
      this.loading = true;
      this.yoneticiListesi = [];
      yoneticiService
        .getYoneticiList(this.postPage, this.sizes, this.selectIcon, 1)
        .then((response) => {
          localStorage.setItem("userDataBGSurec", response.token);
          this.yoneticiListesi = response.liste.map((item) => {
            return {
              ID: item.yoneticiID,
              adSoyad: item.adSoyad,
              yoneticiAdi: item.yoneticiAdi,
              rol: item.rol,
              cepTel: item.cepTelefonu,
              dahiliTel: item.dahiliTelefon,
              sabitTel: item.sabitTelefon,
              sozlesmeKategoriID: item.sozlesmeKategoriID,
            };
          });
          this.total = parseInt(response.count);
          this.loading = false;
        })
        .catch((err) => {
          if (err.responseJSON) {
            let error = err.responseJSON;
            if (error.status == 401) {
              notification.Status(150, this);
            } else if (error.status == 404) {
              this.yoneticiListesi = [];
            } else notification.Status("errorMsg", this, error.errMsg);
          } else {
            //notification.Status(503, this)
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getFirmaYoneticiList() {
      this.loading = true;
      console.log("this.birim", this.birim);
      yoneticiService
        .yoneticiListe2(
          this.birim == 1 ? this.birim : this.sozlesmeKategoriID,
          this.selectIcon,
          this.postPage,
          this.sizes
        )
        .then((response) => {
          if (response.status == 200) {
            localStorage.setItem("userDataBGSurec", response.token);
            this.yoneticiListesi = response.data;
            this.total = response.count;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.yoneticiListesi = [];
          this.total = 0;
          if (err.responseJSON) {
            let error = err.responseJSON;
            if (error.status == 401) {
              notification.Status(150, this);
            } else if (error.status == 404) {
              this.yoneticiListesi = [];
            } else notification.Status("errorMsg", this, error.errMsg);
          } else {
            //notification.Status(503, this)
          }
          this.loading = false;
        });
    },
    yoneticiDurumChange(selection, index, durum) {
      this.$confirm(
        "Personelin durumunu güncellemek istediğinizden emin misiniz?",
        this.$t("src.views.apps.genel.uyari"),
        {
          confirmButtonText: this.$t("src.views.apps.genel.yes"),
          cancelButtonText: this.$t("src.views.apps.genel.no"),
          type: "warning",
        }
      ).then(() => {
        this.stateLoading = true;
        if (durum === 0) {
          yoneticiService
            .yoneticiPasifYap(selection.ID)
            .then((response) => {
              if (response.status == 200) {
                localStorage.setItem("userDataBGSurec", response.token);
                notification.Status("success", this, response.msg);
                this.getFirmaYoneticiList();
                //this.getYoneticiCount();
              }
              this.stateLoading = false;
            })
            .catch((err) => {
              if (err.responseJSON) {
                let error = err.responseJSON;
                if (error.status == 401) {
                  notification.Status(150, this);
                } else notification.Status("errorMsg", this, error.errMsg);
              } else {
                //notification.Status(503, this)
              }
              this.stateLoading = false;
            });
        } else if (durum === 1) {
          this.stateLoading = true;
          yoneticiService
            .yoneticiAktifYap(selection.ID)
            .then((response) => {
              if (response.status == 200) {
                localStorage.setItem("userDataBGSurec", response.token);
                notification.Status("success", this, response.msg);
                this.getFirmaYoneticiList();
                // this.getYoneticiCount();
              }
              this.stateLoading = false;
            })
            .catch((err) => {
              if (err.responseJSON) {
                let error = err.responseJSON;
                if (error.status == 401) {
                  notification.Status(150, this);
                } else notification.Status("errorMsg", this, error.errMsg);
              } else {
                //notification.Status(503, this)
              }
              this.stateLoading = false;
            });
        }
      });
    },
    personelAra() {
      this.postPage = 0;
      this.page = 1;
      this.dateVisible = false;

      if (
        this.personelAdiAra.length > 0 ||
        this.personelKullaniciAdiAra.length > 0 ||
        this.yetkiTuruAra.length > 0 ||
        this.birimAra.length > 0 ||
        this.telefonAra.length > 0 ||
        this.dahiliAra.length > 0 ||
        this.sabitAra.length > 0
      ) {
        try {
          this.searchLoading = true;
          yoneticiService
            .yoneticiFilter(
              this.personelAdiAra,
              this.personelKullaniciAdiAra,
              this.yetkiTuruAra,
              this.birimAra,
              this.telefonAra,
              this.dahiliAra,
              this.sabitAra,
              this.selectIcon,
              this.postPage,
              this.sizes,
              this.birim == 1 ? this.birim : this.sozlesmeKategoriID
            )
            .then((response) => {
              if (response.status == 200) {
                this.yoneticiListesi = response.data;
                this.total = response.count;
              }
              localStorage.setItem("userDataBGSurec", response.token);
              this.searchLoading = false;
            })
            .catch((err) => {
              if (err.responseJSON) {
                let error = err.responseJSON;
                if (error.status == 401) {
                  notification.Status(150, this);
                } else if (error.status == 404) {
                  this.yoneticiListesi = [];
                }
              } else {
                //notification.Status(503, this)
              }
              this.searchLoading = false;
            });
        } catch (e) {
          // notification.Status("danger", this, "An error occurred during the customer search process.")
          this.searchLoading = false;
        }
      } else {
        if (this.radio === "-1") this.getYoneticiList();
        else this.getFirmaYoneticiList();
      }
    },
    routeYonetici(title, scope) {
      //console.log("scope")
      //console.log(scope)
      if (scope) {
        this.$store.commit("changeYoneticiUpdateData", scope);
      }
      functions.routeSayfa(title, this);
    },
    handleSizeChange(val) {
      this.sizes = val;
      if (this.radio === "-1") {
        this.getYoneticiList();
      } else {
        this.getFirmaYoneticiList();
      }
      // this.getYoneticiCount();
    },
    pageChange(val) {
      this.postPage = (val - 1) * this.sizes;
      if (this.radio === "-1") {
        this.getYoneticiList();
      } else {
        this.getFirmaYoneticiList();
      }
    },
    indexMethod(index) {
      return (this.page - 1) * 10 + index + 1;
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and(max-width: 1200px) {
  .personellist-info-switch {
    display: flex !important;
    justify-content: end;
  }
  .personellist-info-icons {
    display: flex !important;
    margin-top: 20px;
    justify-content: start;
  }
}

@media screen and(max-width: 768px) {
  .personellist-info-switch {
    display: flex !important;
    justify-content: start;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .card-base {
    padding: 5px;
  }
}

.el-pagination {
  margin-top: 20px;
  float: right;
}

.el-radio-button--mini .el-radio-button__inner label {
  position: relative;
  bottom: 5px;
}
</style>
<style lang="scss">
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.el-radio-button--mini .el-radio-button__inner {
  padding: 0 10px !important;
  font-size: 11px;
  border-radius: 0;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
<style>
.el-table .cell {
  line-height: 17px !important;
}

.el-table thead {
  line-height: 30px !important;
}

.personelButon {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  margin-top: 1px !important;
  margin-bottom: 1px !important;
  font-size: 0 !important;
  line-height: 1 !important;
}

.buttonDel {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  margin-top: 1px !important;
  margin-bottom: 1px !important;
  font-size: 0 !important;
  line-height: 1 !important;
}
</style>
